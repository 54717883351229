import type { AssetTypeValue } from '../api';

interface GetAssetTypeProps {
  asset_type: AssetTypeValue;
}
interface GetAssetTypeResult {
  isTrack: boolean;
  isPhoto: boolean;
  isVideo: boolean;
}

const getAssetType = ({ asset_type }: GetAssetTypeProps): GetAssetTypeResult => {
  return {
    isTrack: asset_type === 'track',
    isPhoto: asset_type === 'image',
    isVideo: asset_type === 'video',
  };
};

export default getAssetType;
