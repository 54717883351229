import type { QueryKey } from '@tanstack/react-query';
import type { FilteringFields, TagGroupsFilteringFields } from './types';

export default {
  asset: (id: number) => ['assets', id] as QueryKey,
  assetsList: (params: Partial<FilteringFields>) => {
    const { q, sorting, tags, favourite } = params;
    return ['assets', q, sorting, tags, favourite] as QueryKey;
  },
  tagGroups: ({ q }: Partial<TagGroupsFilteringFields>) => ['assets', 'tags', q] as QueryKey,
};
