import type { SxProps, Theme } from '@mui/material';
import { CssBaseline, Box } from '@mui/material';
import type { FC, ReactNode } from 'react';
import Sidebar from './Sidebar';
import { useBreakpoints } from 'hooks';

interface LayoutProps {
  children: ReactNode;
  childrenContainerClasses?: SxProps<Theme>;
}

const Layout: FC<LayoutProps> = ({ children, childrenContainerClasses }) => {
  const { isBreakpoint } = useBreakpoints('sm');

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexGrow: 1, width: '100%', minHeight: '100vh', position: 'relative', zIndex: 1, }}>
        <Sidebar />
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            pt: isBreakpoint ? 10 : 3.5,
            pb: 3,
            px: 3,
            ...childrenContainerClasses,
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
export default Layout;
