import { Box } from '@mui/material';
import type { TagGroupEntry } from '../api';
import { useTagGroups } from '../api';
import { useCallback } from 'react';
import TagAccordion from '../../../components/common/Accordion';
import Checkbox from '../../../components/common/Checkbox';

const TagsAccordions = () => {
  const {
    data: { data: tagGroups },
  } = useTagGroups();
  const tagGroupsMidIndex = Math.floor(tagGroups.length / 2);

  const tagMapper = useCallback(
    (tg: TagGroupEntry) => (
      <TagAccordion
        key={tg.group_id}
        summary={tg.group_name}
        details={tg.tags.map((t) => (
          <Checkbox name={'tags.' + t.tag_name + ':' + t.tag_id} key={t.tag_id} label={t.tag_name} />
        ))}
      />
    ),
    []
  );

  return (
    <Box sx={{ display: 'inline-flex', gap: 3, mb: 3 }}>
      <Box sx={{ width: '100%' }}>{tagGroups.slice(0, tagGroupsMidIndex).map(tagMapper)}</Box>
      <Box sx={{ width: '100%' }}>{tagGroups.slice(tagGroupsMidIndex).map(tagMapper)}</Box>
    </Box>
  );
};

export default TagsAccordions;
