import AddIcon from '@mui/icons-material/Add';
import { Box, Button, } from "@mui/material";
import TitlePageStyles from 'components/common/TitlePageStyles';
import {
  AssetsTopPanel,
  AssetsList,
  AssetsContextProvider,
  AssetsListSkeleton,
  AssetsSearch,
  AssetScrollTop,
  useAssetsListParams
} from 'features/assets';
import { Suspense, type FC } from "react";
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import { Titled } from "react-titled";

const AssetsPage: FC = () => {
  const { tags } = useAssetsListParams();
  const formMethods = useForm({
    defaultValues: {
      searchTags: tags ? tags.reduce((collection, tagId) => ({ ...collection, [`${tagId}`]: true, }), {}) : {},
    }
  });

  return (
    <>
      <Titled title={(title) => `ADAM | ${title}`} />
      <FormProvider {...formMethods}>
        <AssetsContextProvider>
          <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, }}>
            <TitlePageStyles>Assets</TitlePageStyles>
            <AssetsSearch />
            <Button
              disableElevation
              component={Link}
              to={'/assets/create'}
              variant="contained"
              color="primary"
              sx={{ padding: 0, minWidth: 40, minHeight: 40, borderRadius: 20, }}>
              <AddIcon sx={{ color: 'white', fontSize: 22 }} />
            </Button>
          </Box>
          <AssetsTopPanel />
          <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, }}>
            <Suspense fallback={<AssetsListSkeleton />}>
              <AssetsList />
            </Suspense>
          </Box>
          <AssetScrollTop />
        </AssetsContextProvider>
      </FormProvider>
    </>
  );
};

export default AssetsPage;
