import { Box, LinearProgress, styled } from "@mui/material";
import type { FC } from "react";
import AssetsFavouritesFilter from "./AssetsFavouritesFilter";
import AssetsTypesList from "./AssetsTypesList";
import AssetsSorting from "./AssetsSorting";
import { useIsFetching } from "@tanstack/react-query";
import AssetsSearchValues from "./search/AssetsSearchValues";
import { AssetsCountResults } from "./AssetsListComponents";

export const AssetsTopPanelContainer = styled(Box)(({ theme: t }) => `
  z-index: 4;
  display: flex;
  flex-direction: column;
  position: sticky;
  left: 0;
  margin: 0px -24px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  min-height: 56px;
  min-height: 56px;
  background-color: white;
  box-sizing: content-box;

  ${t.breakpoints.down('md')} {
    padding-top: 8px;
    padding-bottom: 8px;
  };

  ${t.breakpoints.up('sm')} {
    top: 0;
  };

  ${t.breakpoints.down('sm')} {
    top: 56px;
  };
`);

export const AssetsTopPanelNav = styled(Box)(() => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`);

export const AssetsTopPanelNavigation = styled(Box)(({ theme: t }) => `
  display: flex;
  align-items: center;

  ${t.breakpoints.down('md')} {
    width: 100%;

  ${t.breakpoints.up('sm')} {
    justify-content: end;
  };

  ${t.breakpoints.down('sm')} {
    width: 100%;
    justify-content: start;
    flex-direction: row-reverse;
  };
`);

const AssetsTopPanel: FC = () => {
  const isFetching = useIsFetching({queryKey: ['assets']});

  return (
    <AssetsTopPanelContainer>

      <AssetsTopPanelNav>
        <AssetsFavouritesFilter />

        <AssetsTopPanelNavigation>
          <AssetsCountResults />
          <AssetsTypesList />
          <AssetsSorting />
        </AssetsTopPanelNavigation>
      </AssetsTopPanelNav>

      <AssetsSearchValues />
      {isFetching ? (
        <LinearProgress sx={{ position: 'absolute', bottom: -4, left: 0, right: 0, height: 4, zIndex: 10, mx: 3, }} />
      ) : null}

    </AssetsTopPanelContainer>
  )
}

export default AssetsTopPanel;
