import type { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { SelectProps } from '@mui/material';
import { Select as MuiSelect, OutlinedInput, MenuItem, Checkbox, ListItemText, Typography } from '@mui/material';
import type { Option, OptionValue } from 'types/shared';
import { Box } from '@mui/system';

export interface ControlledSelectProp extends Omit<SelectProps<OptionValue | OptionValue[]>, 'name'> {
  name: string;
  options: Option[];
}
const Select: FC<ControlledSelectProp> = ({ name, options, label, required, multiple = false, ...restProps }) => {
  const { control, formState, watch, setValue } = useFormContext();
  const error = formState.errors[name];

  if (multiple) {
    const fieldValue: OptionValue[] = (watch(name) ?? []) as OptionValue[];
    const isAllSelected = options.length > 0 && fieldValue.length === options.length;

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => {
          const selectedOptionsLength = fieldValue.length;

          return (
            <Box sx={{ width: '100%' }}>
              {label && (
                <Typography variant="subtitle2" mb={1}>
                  {label}
                  {required && '*'}
                </Typography>
              )}
              <MuiSelect
                value={(value as OptionValue[] | undefined) ?? []}
                onChange={(event) => {
                  if (typeof event.target.value === 'object' && event.target.value.includes('selectAll')) {
                    return;
                  }
                  onChange(event);
                }}
                multiple
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                fullWidth
                variant="outlined"
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) =>
                  options
                    .filter((o) => (selected as OptionValue[]).includes(o.value))
                    .map((o) => o.label)
                    .join(', ')
                }
                error={!!error}
                {...restProps}
              >
                <MenuItem
                  value="selectAll"
                  onClick={() => {
                    setValue(name, isAllSelected ? [] : options.map((o) => o.value));
                  }}
                  sx={{ p: 0 }}
                >
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={selectedOptionsLength > 0 && selectedOptionsLength < options.length}
                  />
                  <ListItemText
                    sx={(t) => ({ display: 'flex', alignItems: 'center', color: t.palette.primary.main })}
                    primary="Select all"
                  />
                </MenuItem>
                {options.map((o) => (
                  <MenuItem sx={{ p: 0 }} key={o.value} value={o.value}>
                    <Checkbox checked={fieldValue.includes(o.value)} />
                    <ListItemText primary={o.label} />
                  </MenuItem>
                ))}
              </MuiSelect>
              {error?.message && (
                <Typography variant="subtitle1" fontSize={12} sx={{ mt: '3px' }} color="error">
                  {error.message as string}
                </Typography>
              )}
            </Box>
          );
        }}
      />
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <Box sx={{ width: '100%' }}>
            {label && (
              <Typography variant="subtitle2" mb={1}>
                {label}
                {required && '*'}
              </Typography>
            )}
            <MuiSelect
              {...field}
              fullWidth
              sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
              variant="outlined"
              input={<OutlinedInput />}
              error={!!error}
              {...restProps}
            >
              {options.map((o) => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </MuiSelect>
            {error?.message && (
              <Typography variant="subtitle1" fontSize={12} sx={{ mt: '3px' }} color="error">
                {error.message as string}
              </Typography>
            )}
          </Box>
        );
      }}
    />
  );
};

export default Select;
