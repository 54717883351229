import { Box } from "@mui/system";
import TagAccordion from "components/common/Accordion";
import type { TagGroupEntry } from "features/assets";
import type { FC } from "react";
import Checkbox from "components/common/Checkbox";

const AssetsSearchTagItem: FC<{ tagGroup: TagGroupEntry }> = ({ tagGroup }) => {
  return (
    <Box>
      <TagAccordion
        summary={tagGroup.group_name}
        details={tagGroup.tags.map((t) => (
          <Checkbox key={`${t.tag_id}:${t.tag_name}`} name={'searchTags.' + t.tag_id} label={t.tag_name} />
        ))}
      />
    </Box>
  )
};

export default AssetsSearchTagItem;
