import type { FC } from 'react';
import { Titled } from 'react-titled';
import { Container, Box } from '@mui/material';
import { Layout } from 'components/layout';
import { Suspense } from 'react';
import CircularSpinner from '../../components/common/CircularSpinner';
import { AssetDetails, AssetsContextProvider, AssetsSearch, useAssetsListParams } from 'features/assets';
import { FormProvider, useForm } from 'react-hook-form';
import BreadcrumbLink from 'components/layout/BreadcrumbLink';

const AssetDetailsPage: FC = () => {
  const { tags } = useAssetsListParams();
  const formMethods = useForm({
    defaultValues: {
      searchTags: tags ? tags.reduce((collection, tagId) => ({ ...collection, [`${tagId}`]: true, }), {}) : {},
    }
  });

  return (
    <FormProvider {...formMethods}>
      <AssetsContextProvider>
        <Layout childrenContainerClasses={{ paddingTop: '24px', paddingBottom: '40px', }}>
          <Titled title={(title) => `Asset details | ${title}`} />
          <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'top', pt: 1, mb: 6, height: 48 }}>
            <BreadcrumbLink to="/assets" title="Assets" />
            <AssetsSearch />
          </Box>
          <Container maxWidth={false} sx={{ '&': { p: 0 }, }}>
            <Suspense fallback={<CircularSpinner />}>
              <AssetDetails />
            </Suspense>
          </Container>
        </Layout>
      </AssetsContextProvider>
    </FormProvider>
  );
};

export default AssetDetailsPage;
