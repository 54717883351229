import { createContext, useContext } from 'react';

export type ListType = 'row' | 'column';

interface CallbackFunctionParam {
  param : 'q' | 'tags'
}
interface CallbackFunction {
  current: (arg?: CallbackFunctionParam) => void;
};

export interface AssetsStateValue {
  isVisibleFilter: boolean;
  handleVisibleFilter: () => void;

  listType: ListType;
  handleListType: (value: ListType) => void;

  isGrid: boolean;
  isList: boolean;

  handleToggleAssetPreview: (id?: number) => void;
  isOpenPreview: boolean;
  assetPreviewId: number | undefined;

  countAssets: number | null;
  handleSetCountAssets: (value: number) => void;

  onResetValuesCallback: CallbackFunction,
}

export type AuthContextType = AssetsStateValue;

export const AssetsContext = createContext<AuthContextType>({} as AuthContextType);

export function useAssets() {
  return useContext(AssetsContext);
}
