import { Box, Button, Divider, List, ListItem, styled, Typography } from '@mui/material';
import BgForbidden from 'assets/bg-forbidden.webp';
import LogoAdam from 'assets/logo-adam-grey.svg?react';
import { useParams } from 'react-router';
import { useUsersList } from '../api';
import BreadcrumbLink from 'components/layout/BreadcrumbLink';
import { Auth } from 'aws-amplify';
import { Logout as LogoutIcon } from '@mui/icons-material';

const GradientBackground = styled(Box)`
  padding-left: 40px;
  padding-right: 40px;
  background: url(${BgForbidden}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const Forbidden = () => {
  const { projectName, projectId } = useParams();

  const handleSignOut = async () => {
    await Auth.signOut();
  };

  const { data: users } = useUsersList({
    params: {
      project_id: projectId ? [Number(projectId)] : [],
      access_level: ['admin'],
      offset: 0,
      limit: 50,
    },
  });

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, width: '100%', flexDirection: 'row', height: '100vh' }}>
      <GradientBackground>
        <Box sx={{ pt: 4, pb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 11.5 }}>
          <LogoAdam />
          <Button
            variant="text"
            size="large"
            component="button"
            onClick={handleSignOut}
            startIcon={<LogoutIcon />}
            sx={(t) => ({ mr: 1, color: t.palette.primary.main, fontWeight: 500, textTransform: 'uppercase' })}
          >
            log out
          </Button>
        </Box>
        <Box
          sx={{
            width: '90%',
            mx: 'auto',
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            p: 5,
            borderRadius: 1.5,
            height: 480,
            overflowY: 'scroll',
          }}
        >
          {projectId && <BreadcrumbLink to="/assets" title="All assets" />}
          <Typography
            mb={2}
            variant="h2"
            sx={(t) => ({
              [t.breakpoints.up('md')]: {
                fontSize: t.typography.h1.fontSize,
              },
            })}
            fontWeight={400}
          >
            Request access to view {projectName ?? ''} content
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 8 }}>
            <Box sx={{ minWidth: 32 }}>
              <LogoAdam />
            </Box>
            <Typography
              variant="body1"
              sx={(t) => ({
                [t.breakpoints.up('md')]: {
                  fontSize: t.typography.h3.fontSize,
                },
              })}
            >
              Contact any of these project members to get access
            </Typography>
          </Box>
          <List>
            {users.data.map(({ email, id }) => (
              <>
                <ListItem
                  key={id}
                  sx={{ py: 2, width: '100%', display: 'flex', justifyContent: 'space-between', px: 0 }}
                >
                  <Typography variant="body1">{email}</Typography>
                </ListItem>
                <Divider component="li" sx={{ borderColor: 'black' }} />
              </>
            ))}
          </List>
        </Box>
      </GradientBackground>
    </Box>
  );
};

export default Forbidden;
