import type { FC} from "react";
import { useCallback, useMemo } from "react";
import { Cancel, Search } from "@mui/icons-material";
import { Box, Button, Chip } from "@mui/material";
import { sortTagGroups, useTagGroups, useAssetsListParams, useSearchTagsState, useAssets } from "features/assets";

const AssetsSearchValues: FC = () => {
  const { onResetValuesCallback } = useAssets();
  const { tags, setTags, q } = useAssetsListParams();
  const { selectedTags, onSelectTag, onResetStateTags } = useSearchTagsState();
  const { data: searchTags } = useTagGroups({
    config: {
      enabled: false,
      select: ({ data }) => sortTagGroups(data, tags as number[]),
    },
  });

  const handleDeleteTag = useCallback((tagId: number) => {
    onSelectTag(tagId, false);

    const selectedTagsState = selectedTags.filter((selectedTag) => selectedTag !== tagId);
      setTags(selectedTagsState);
  }, [selectedTags, setTags, onSelectTag]);

  const handleResetAll = useCallback(() => {
    onResetStateTags();
    onResetValuesCallback.current();
  }, [onResetValuesCallback, onResetStateTags])

  const memoSearchValues = useMemo(() => ((q?.length) ?? searchTags.length) ? (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', pb: 1, }}>
      <Button variant="text" sx={t => ({
        borderRadius: t.borderRadius.sm,
        backgroundColor: 'transparent',
        marginRight: 1,
        height: 24,
      })} onClick={handleResetAll}>Reset all</Button>
      {q?.length ? <Chip
        label={q}
        icon={<Search sx={t => ({ width: 18, color: t.palette.primary.main, ml: 1, })} />}
        deleteIcon={<Cancel />}
        onDelete={() => { onResetValuesCallback.current({ param: 'q', }); }}
        sx={ t => ({
          mb: 1,
          '& .MuiChip-label': {
            color: t.palette.primary.main
          },
          '.MuiChip-deleteIcon': {
            color: t.palette.grey[600]
          },
          '.MuiChip-deleteIcon:hover': {
            color: t.palette.grey[800]
          },
          '&.MuiChip-root': {
            backgroundColor: t.palette.grey[100]
          },
        })} /> : null}
      {searchTags.map(tag => (
        <Chip
          key={`selectedTag.${tag.tag_id}:${tag.tag_name}`}
          label={tag.tag_name}
          deleteIcon={<Cancel />}
          onDelete={() => { handleDeleteTag(tag.tag_id); }} />
      ))}
    </Box>
  ) : null, [q, handleDeleteTag, handleResetAll, searchTags, onResetValuesCallback]);

  return memoSearchValues;
}

export default AssetsSearchValues;
