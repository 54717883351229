import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import type { ListResponse, PaginationParams } from 'lib/api';
import { apiClient } from 'lib/api';
import queryString from 'query-string';
import type { FilteringFields, AssetEntry } from './types';
import queryKeys from './queryKeys';

export const getAssetsList = async ({ params }: { params: Partial<FilteringFields> & PaginationParams }, signal?: AbortSignal): Promise<ListResponse<AssetEntry>> => {
  const res = await apiClient.get<ListResponse<AssetEntry>>(`/assets`, {
    params, signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

export const useAssetsList = ({ params }: { params: Partial<FilteringFields> }) => {
  return useSuspenseInfiniteQuery({
    queryFn: ({ pageParam }) => getAssetsList({ params: { ...params, offset: pageParam, limit: 50 } }),
    queryKey: queryKeys.assetsList(params),

    select: (data) => ({
      count: data.pages[0].count,
      data: data.pages.flatMap((page: ListResponse<AssetEntry>) => page.data)
    }),

    getNextPageParam: (lastPage) => {
      return lastPage.data.length ? lastPage.offset + lastPage.limit : null
    },
    initialPageParam: 0,
  })
};
