import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import AddTagGroupDialog from "./AddTagGroupDialog";
import { useModal } from "hooks";
import { useCallback } from "react";

const AddTagGroup = () => {
  const {isOpen, open, close} = useModal();
  const handleOpen = useCallback(() => {
    open();
  }, [open]);

  return (
    <>
      <Button
        onClick={handleOpen}
        disableElevation
        variant="contained"
        color="primary"
        sx={{ padding: 0, minWidth: 40, minHeight: 40, borderRadius: 20, }}>
      <AddIcon sx={{ color: 'white', fontSize: 22, }} />
    </Button>
    <AddTagGroupDialog isOpen={isOpen} onClose={close} />
    </>
  )
}

export default AddTagGroup;
