import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { SynchronousQueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { TagGroupEntry, TagGroupsFilteringFields } from './types';
import queryKeys from './queryKeys';

export interface GetTagGroupsOptions {
  params?: Partial<TagGroupsFilteringFields>;
};

interface GetTagGroupsResponse {
  data: TagGroupEntry[]
};

export const getTagGroups = async (
  { params }: GetTagGroupsOptions,
  signal?: AbortSignal
): Promise<GetTagGroupsResponse> => {
  const res = await apiClient.get<GetTagGroupsResponse>(`/assets/tags`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  return {
    data: res.data.data,
  };
};

interface UseTagGroupsOptions<TData> {
  config?: SynchronousQueryConfig<typeof getTagGroups, TData>;
  params?: GetTagGroupsOptions['params'];
}

export const useTagGroups = <TData = GetTagGroupsResponse>({ config, params }: UseTagGroupsOptions<TData> = {}) => {
  const { q } = { ...params };

  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getTagGroups({ params }, signal),
    queryKey: queryKeys.tagGroups({q}),
  });
};
