import { useParams } from 'react-router';
import { useAsset } from '../api';
import AssetContentView from './AssetContentView';
import { Box } from '@mui/system';

const MediaBlock = () => {
  const { assetId } = useParams();
  const { data: asset } = useAsset({ id: Number(assetId) });

  return (
    <Box
      sx={(t) => ({
        display: 'flex',
        justifyContent: 'center',
        width: 420,
        maxHeight: 420,
        [t.breakpoints.up('xl')]: {
          width: 700,
          maxHeight: 700,
        },
        [t.breakpoints.down('md')]: {
          mb: 3,
        },
      })}
    >
      <AssetContentView thumbnail={asset.thumbnail} type={asset.asset_type} url={asset.media_url} />
    </Box>
  );
};

export default MediaBlock;
