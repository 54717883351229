import { Controller, useFormContext } from 'react-hook-form';
import type { SxProps } from '@mui/material';
import { Box, TextField, Typography } from '@mui/material';
import type { TextFieldProps } from '@mui/material/TextField/TextField';
import type { FC } from 'react';

interface InputProps extends Omit<TextFieldProps, 'name'> {
  name: string;
  containerSx?: SxProps;
}

const Input: FC<InputProps> = ({ label, name, required, containerSx, ...restProps }) => {
  const { control, formState } = useFormContext();
  const error = formState.errors[name];

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Box sx={containerSx}>
          {label && (
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {label}
              {required && '*'}
            </Typography>
          )}
          <TextField sx={{ display: 'block' }} variant="outlined" error={!!error} fullWidth {...field} {...restProps} />
          {error?.message && (
            <Typography variant="subtitle1" fontSize={12} sx={{ mt: '3px' }} color="error">
              {error.message as string}
            </Typography>
          )}
        </Box>
      )}
    />
  );
};

export default Input;
