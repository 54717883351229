import { Backdrop, Button, IconButton, TextField, Typography } from "@mui/material";
import { FilterList as FilterListIcon, KeyboardArrowDown as KeyboardArrowDownIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Box } from "@mui/system";
import { useBreakpoints, useModal } from "hooks";
import type { ChangeEvent, FC, FocusEvent } from "react";
import { debounce, isUndefined } from "lodash-es";
import { Suspense, useCallback, useMemo, useRef, useState } from "react"
import AssetsSearchResultTags from "./AssetsSearchResultTags";
import AssetsSearchAllTags from "./AssetsSearchAllTags";
import queryStringParser from 'query-string';
import {
  AssetsQueryButton,
  AssetsSearchAllTagsSkeleton,
  AssetsSearchButton,
  AssetsSearchContainer,
  AssetsSearchResultContainer,
  AssetsSearchPositionContainer,
  AssetsSearchResultTagsSkeleton,
  SearchByStringSuggest
} from "./AssetsSearchComponents";
import { useAssets, useAssetsListParams, useTagGroups } from "features/assets";
import AssetsSelectedTags from "./AssetsSelectedTags";
import { useSearchTagsState } from "features/assets";
import { useLocation, useNavigate } from "react-router";

const AssetsSearch: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { onResetValuesCallback } = useAssets();
  const { isBreakpoint } = useBreakpoints('sm');
  const { tags, setTags, q, setQ } = useAssetsListParams();
  const { selectedTags, onResetStateTags, onSelectTags, onSelectTag } = useSearchTagsState();
  const { isOpen: isShowAssetsSearch, close: onClose, open: onOpen } = useModal();
  const [isShowAllTags, setShowAllTags] = useState(false);
  const [isShowResultTags, setShowResultTags] = useState(false);
  const [isShowShadow, setShowShadow] = useState(false);
  const searchFieldRef = useRef<HTMLInputElement>(null);

  const [queryString, setQueryString] = useState(() => q?.length ? q : '');
  const [queryFieldValue, setQueryFieldValue] = useState(() => {
    const defaultValue = q?.length ? q : '';
    setShowResultTags(() => defaultValue.length >= 2);
    return defaultValue;
  });

  useTagGroups({ config: { enabled: location.pathname !== '/assets', }});

  const setQueryStringDebounce =
    useMemo(() => debounce(setQueryString, 300), [setQueryString]);

  const handleAllTags = useCallback(() => {
    setShowAllTags(prev => !prev);
  }, [setShowAllTags]);

  const handleFieldFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    e.target.select();

    if (isShowAssetsSearch) return;

    onOpen();
    setShowResultTags(!isUndefined(q) && q.length >= 2);
    setQueryString(q?.length ? q : '');
  }, [isShowAssetsSearch, onOpen, q, setQueryString, setShowResultTags]);

  const handleFieldSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQueryFieldValue(value);

    if (value.length >= 2 || !value.length) {
      setQueryStringDebounce(value);
      setShowAllTags(false);
      setShowResultTags(!!value.length);
    }
  }, [setShowAllTags, setShowResultTags, setQueryStringDebounce]);

  const handleResetAll = useCallback(() => {
    setQueryString('');
    setQueryFieldValue('');
    setShowAllTags(false);
    setShowResultTags(false);
    onResetStateTags();
    setTags(undefined);
    setQ(undefined);
  }, [
    setQueryString,
    setShowAllTags,
    setShowResultTags,
    onResetStateTags,
    setQueryFieldValue,
    setTags,
    setQ
  ]);

  const handleClose = useCallback(() => {
    onClose();
    setQueryFieldValue(() => q?.length ? q : '');
    onSelectTags(tags as number[]);

    setShowAllTags(false);
    setShowResultTags(false);
  }, [q, tags, setQueryFieldValue, onSelectTags, onClose, setShowAllTags, setShowResultTags]);

  const handleOnSearch = useCallback(() => {
    if( location.pathname === '/assets' ) {
      selectedTags.length ? setTags(selectedTags) : setTags(undefined);
      queryString.length ? setQ(queryString) : setQ(undefined);
      onClose();
    } else {
      navigate(`/assets?${queryStringParser.stringify({
        tags: selectedTags.length ? selectedTags : undefined,
        q: queryFieldValue.length ? queryFieldValue : undefined,
      }, { arrayFormat: 'separator',})}`);
    }
  }, [selectedTags, location.pathname, onClose, setTags, queryString, setQ]);

  const onScrollAllTags = useCallback((value: number) => {
    setShowShadow(!!value);
  }, [setShowShadow]);

  onResetValuesCallback.current = useCallback((args) => {
    switch(args?.param) {
      case undefined:
      default:
        handleResetAll();
        break;
      case 'q':
        setQ(undefined);
        setQueryFieldValue('');
        break;
      case 'tags':
        setTags(undefined);
        break;
    }
  }, [setQ, setQueryFieldValue, setTags, handleResetAll]);

  return (
    <AssetsSearchContainer isShowAssetsSearch={isShowAssetsSearch}>
      <AssetsSearchPositionContainer isShowAssetsSearch={isShowAssetsSearch}>
        <Box sx={{
          pl: isShowAssetsSearch ? 0.5 : 0,
          display: 'flex',
          flexWrap: 'nowrap',
          zIndex: 3,
          backgroundColor: 'white',
        }}>
          {(isBreakpoint && isShowAssetsSearch) && (
            <IconButton onClick={handleClose} sx={{ ml: -0.5, mr: 1, height: 40, width: 40, }}>
              <ArrowBackIcon sx={{ width: 20, height: 20, }} />
            </IconButton>
          )}
          <TextField
            inputRef={searchFieldRef}
            id={isShowAssetsSearch ? 'assets-search-field' : undefined}
            hiddenLabel
            placeholder="Search by name, tags or comment"
            size="small"
            sx={{ width: '100%', }}
            InputProps={{
              sx: (t) => ({
                width: '100%',
                minHeight: 40,
                maxHeight: 40,
                borderRadius: 24,
                backgroundColor: 'white',
                fontSize: t.typography.body1.fontSize,
              })
            }}
            value={queryFieldValue}
            onFocus={handleFieldFocus}
            onChange={handleFieldSearch}
            onKeyUpCapture={(e) => {
              if(e.key === 'Enter' && queryFieldValue.length >= 2) {
                handleOnSearch();
                searchFieldRef.current?.blur();
              }
            }}
          />
          {(isShowAssetsSearch && !isBreakpoint) && <AssetsSearchButton
            color="primary"
            variant="contained"
            disableElevation
            disabled={(queryFieldValue.length < 2) && !selectedTags.length}
            onClick={handleOnSearch}>
            Search
          </AssetsSearchButton>}
        </Box>
        {isShowAssetsSearch && (
          <>
            <AssetsSearchResultContainer>
              <AssetsSelectedTags selectedTags={selectedTags} onSelectTag={onSelectTag} />
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pb: 2,
                px: 0.5,
                position: 'relative',
                zIndex: 3,
                boxShadow: isShowShadow ? '0px 4px 5px -4px rgb(0,0,0, 0.4)' : 'none',
              }}>
                <Button
                  onClick={handleAllTags}
                  startIcon={<FilterListIcon />}
                  endIcon={<KeyboardArrowDownIcon sx={{ transform: `rotate(${isShowAllTags ? 180 : 0}deg)`, transition: 'transform .2s' }} />}
                  variant="text"
                  sx={t => ({
                    backgroundColor: isShowAllTags ? t.palette.grey[100] : 'none',
                    borderRadius: t.borderRadius.sm,
                    fontSize: t.typography.caption.fontSize,
                    height: 30,
                  })}>All tags</Button>

                <Button
                  variant="text"
                  color="error"
                  onClick={handleResetAll}>Reset all</Button>
              </Box>

              <Suspense fallback={<AssetsSearchAllTagsSkeleton />}>
                {isShowAllTags && <AssetsSearchAllTags onScrollCallback={onScrollAllTags} />}
              </Suspense>

              <Suspense fallback={<AssetsSearchResultTagsSkeleton />}>
                {!!(isShowResultTags && queryString.length) && <AssetsSearchResultTags q={queryString} />}
              </Suspense>
              {(queryString.length && queryFieldValue.length) ? (
                <SearchByStringSuggest>
                  <Typography
                    component={'span'}
                    sx={t => ({
                      fontSize: t.typography.body1.fontSize,
                      color: t.palette.grey[600],
                      mb: 1.5,
                    })}>
                    Search by names, comments and AI prompts
                  </Typography>
                  <Box>
                    <AssetsQueryButton onClick={handleOnSearch} value={queryFieldValue} />
                  </Box>
                </SearchByStringSuggest>
              ) : null}
              {isBreakpoint && (
                <Box sx={{ position: 'relative', zIndex: 5, display: 'flex', justifyContent: 'center', height: 56, }}>
                  <AssetsSearchButton
                    color="primary"
                    variant="contained"
                    disableElevation
                    disabled={(queryFieldValue.length < 2) && !selectedTags.length}
                    onClick={handleOnSearch}>
                    Search
                  </AssetsSearchButton>
                </Box>
              )}
            </AssetsSearchResultContainer>
          </>
        )}
      </AssetsSearchPositionContainer>
      <Backdrop sx={{ zIndex: 8, }} open={isShowAssetsSearch} onClick={handleClose}></Backdrop>
    </AssetsSearchContainer>
  )
};

export default AssetsSearch;
