import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useCallback, type FC } from 'react';
import useUsersListParams from '../hooks/useUsersListParams';

const UsersTableHead: FC = () => {
  const { desc, setDesc, orderBy, setOrderBy } = useUsersListParams();

  const handleToggleSort = useCallback(
    (id: string) => {
      if (id === orderBy) setDesc((prevState) => !prevState);
      else {
        setOrderBy(id);
        setDesc(true);
      }
    },
    [orderBy, setOrderBy, setDesc]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ minWidth: 200, width: 500 }} style={{ paddingLeft: '4px' }}>
          <TableSortLabel
            active={orderBy === 'email'}
            direction={desc ? 'desc' : 'asc'}
            onClick={() => {
              handleToggleSort('email');
            }}
          >
            Email
          </TableSortLabel>
        </TableCell>
        <TableCell sx={{ minWidth: 200, width: 300 }}>Access level</TableCell>
        <TableCell sx={{ minWidth: 200 }}>Project</TableCell>
        <TableCell sx={{ minWidth: 100 }}>Tag</TableCell>
        <TableCell sx={{ minWidth: 200 }}>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default UsersTableHead;
