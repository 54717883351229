import { Box, Button, CircularProgress, Typography } from '@mui/material';
import useMyAccess from '../../users/hooks/useMyAccess';
import type { BaseSyntheticEvent, FC } from 'react';

interface CreateUpdateAssetActionBlockProps {
  onDiscard: () => void;
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>;
  isSubmitting: boolean;
}
const CreateUpdateAssetActionBlock: FC<CreateUpdateAssetActionBlockProps> = ({ onDiscard, onSubmit, isSubmitting }) => {
  const { hasAssetChangeAccess } = useMyAccess();

  if (!hasAssetChangeAccess) {
    return null;
  }

  return (
    <Box
      sx={(t) => ({
        display: 'flex',
        gap: 1.25,
        borderRadius: 5,
        position: 'fixed',
        right: 24,
        bottom: 40,
        zIndex: 10,
        backgroundColor: 'white',
        p: 1,
        boxShadow:
          '0px 3px 5px -1px rgba(0, 0, 0, 0.10), 0px 6px 10px 0px rgba(0, 0, 0, 0.07), 0px 1px 18px 0px rgba(0, 0, 0, 0.06)',
        [t.breakpoints.down('md')]: {
          bottom: 10,
        },
      })}
    >
      <Button
        sx={{ border: 'none', '&:hover': { border: 'none' }, px: 0.66 }}
        size="small"
        color="error"
        variant="outlined"
        onClick={onDiscard}
      >
        discard
      </Button>

      <Button
        onClick={onSubmit}
        variant="contained"
        disabled={isSubmitting}
        sx={(t) => ({
          textTransform: 'uppercase',
          borderRadius: `${t.shape.borderRadius * 2}px`,
        })}
      >
        {isSubmitting ? (
          <CircularProgress size={20} sx={{ color: 'white' }} />
        ) : (
          <Typography fontWeight={500} variant="body2">
            Save changes
          </Typography>
        )}
      </Button>
    </Box>
  );
};

export default CreateUpdateAssetActionBlock;
