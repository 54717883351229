import type { SelectChangeEvent} from "@mui/material";
import { Box, MenuItem, Select, styled } from "@mui/material";
import type { SortingOptionLabel, SortingOptionValue } from "../api";
import useAssetsListParams from "../hooks/useAssetsListParams";

const AssetsSortingContainer = styled(Box)(({ theme: t }) => `
  ${t.breakpoints.up('sm')} {
    padding-left: 12px;
  };

  ${t.breakpoints.down('sm')} {
    padding-left: 12px;
    width: 100%;
  };
`);
interface Option {
  value: SortingOptionValue;
  label: SortingOptionLabel;
}

const SORTING_OPTIONS: Option[] = [
  { value: 'newest', label: 'Newest' },
  { value: 'oldest', label: 'Oldest' },
];

const AssetsSorting = () => {
  const { sorting, setSorting } = useAssetsListParams();

  const handleSorting = (event: SelectChangeEvent) => {
    setSorting(event.target.value as SortingOptionValue);
  }

  return (
    <AssetsSortingContainer>
      <Select value={sorting as SortingOptionValue} onChange={handleSorting}>
        {SORTING_OPTIONS.map(option => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </AssetsSortingContainer>
  )
};

export default AssetsSorting;
