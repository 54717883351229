import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import type { ReactNode } from 'react';
import ProgressSkeleton from 'components/layout/ProgressSkeleton';
import { useMe } from 'features/users';

const AuthRequired = ({ children, checkAccess = true }: { children: ReactNode; checkAccess?: boolean }) => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);

  const { data: me, isFetched, isFetching } = useMe({ config: { enabled: checkAccess } });

  if (route === 'idle' || isFetching) {
    return <ProgressSkeleton />;
  }

  if (checkAccess && isFetched && !me?.access_level) {
    return <Navigate to="/forbidden" />;
  }

  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};
export default AuthRequired;
