import type { UserAccessLevel } from '../api';
import { useMe } from '../api';

export const PROJECT_DEPENDANT_ROLES: UserAccessLevel[] = ['Viewer'];

const useMyAccess = () => {
  const { data: me, isPending } = useMe();
  const hasUserChangeAccess = me?.access_level === 'Admin' || me?.access_level === 'Supervisor';
  const hasAssetChangeAccess = !!me?.access_level && me.access_level !== 'Viewer';
  const hasUnsetAccess = !isPending && !me?.access_level;

  return { hasUnsetAccess, hasUserChangeAccess, isPending, hasAssetChangeAccess };
};

export default useMyAccess;
