import type { FC} from "react";
import { useCallback } from "react";
import { useAssets, useChangeFavourites } from "..";
import useEnqueueSnackbar from "hooks/useEnqueueSnackbar";
import { CircularProgress, Tooltip, Typography } from "@mui/material";
import { AssetUtilButton } from "./AssetsListComponents";
import { Star, StarBorder } from "@mui/icons-material";

interface AssetAddToFavouritesProps {
  isPreview?: boolean;
  assetId: number;
  favourite: boolean;
};

const AssetAddToFavourites: FC<AssetAddToFavouritesProps> = ({
  assetId,
  favourite,
  isPreview,
}) => {
  const { isList, isGrid } = useAssets();
  const { onShowAlert } = useEnqueueSnackbar();
  const changeFavourites = useChangeFavourites({
    config: {
      onSuccess: () => {
        onShowAlert('Success');
      },

      onError: (err) => {
        onShowAlert('Error');
        console.error(err);
      },
    },
  });

  const handleChangeFavourites = useCallback(() => {
    changeFavourites.mutate({ asset_id: assetId, favourite: !favourite });
  }, [changeFavourites, assetId, favourite]);

  return (
    <Tooltip
      title={isGrid && favourite ? 'Remove from favourites' : isGrid ? 'Add to favourites' : null}
      placement={isPreview ?? isGrid ? 'left' : 'bottom'}
      arrow={isList}
    >
      <AssetUtilButton
        isPreview={isPreview}
        disabled={changeFavourites.isPending}
        disableElevation
        onClick={handleChangeFavourites}
        variant={isPreview ?? isGrid ? 'contained' : 'text'}
      >
        {changeFavourites.isPending ? (
          <CircularProgress
            size={22}
            sx={{ color: isPreview ?? isGrid ? 'white' : 'black', mr: !isPreview && isList ? 1 : 0 }}
          />
        ) : favourite ? (
          <Star
            sx={{ fontSize: 22, color: isPreview ?? isGrid ? 'white' : 'black', mr: !isPreview && isList ? 1 : 0 }}
          />
        ) : (
          <StarBorder
            sx={{ fontSize: 22, color: isPreview ?? isGrid ? 'white' : 'black', mr: !isPreview && isList ? 1 : 0 }}
          />
        )}
        {!isPreview && isList && (
          <Typography
            sx={(t) => ({
              fontSize: t.typography.body2.fontSize,
              fontWeight: 500,
              color: t.palette.primary.main,
              whiteSpace: 'nowrap',
            })}
          >
            Add to favourites
          </Typography>
        )}
      </AssetUtilButton>
    </Tooltip>
  );
};

export default AssetAddToFavourites;
