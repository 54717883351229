import { Suspense } from 'react';
import { Titled } from 'react-titled';
import { Box, Container, Grid, Typography } from '@mui/material';
import CircularSpinner from '../../components/common/CircularSpinner';
import { Layout } from '../../components/layout';
import { AssetForm, CreateUpdateAssetActionBlock, useUpdateAsset } from 'features/assets';
import { FormProvider, useForm } from 'react-hook-form';
import type { AssetFormType } from 'features/assets';
import { yupResolver } from '@hookform/resolvers/yup';
import { assetSchema } from 'features/assets';
import { useAsset } from 'features/assets';
import { useNavigate, useParams } from 'react-router';
import { isEmpty } from 'lodash-es';
import { AssetContentView } from 'features/assets';
import { getAssetTypeFromUrl } from 'features/assets';
import extractSelectedTagsIds from '../../features/assets/utils/extractSelectedTagsIds';

const UpdateAsset = () => {
  const { assetId } = useParams();
  const { data: asset } = useAsset({ id: Number(assetId) });
  const navigate = useNavigate();

  const { mutateAsync, isPending } = useUpdateAsset({
    config: {
      onSuccess: () => {
        navigate('/assets');
      },
    },
  });

  const formMethods = useForm<AssetFormType>({
    resolver: yupResolver(assetSchema),
    defaultValues: {
      name: asset.name,
      mediaUrl: asset.media_url,
      thumbnail: asset.thumbnail ?? '',
      sourceFile: asset.source_file,
      tags: !isEmpty(asset.tags)
        ? asset.tags.reduce<Record<string, boolean>>((acc, currentTag) => {
            const key = `${currentTag.name}:${currentTag.id}`;
            acc[key] = true;
            return acc;
          }, {})
        : {},
      project3D: asset.project_3d_url ?? '',
      comment: asset.comment ?? '',
      useFile: asset.use_file || '',
      aiPrompt: asset.ai_prompt ?? '',
      projects: asset.projects.map((p) => p.id),
      restricted: asset.restricted,
      customer: asset.customer ?? '',
      performer: asset.performer ?? '',
    },
  });

  const { reset, handleSubmit, watch } = formMethods;

  const [thumbnail, url] = [watch('thumbnail'), watch('mediaUrl')];

  const onSubmit = async ({
    name,
    mediaUrl,
    aiPrompt,
    customer,
    tags,
    sourceFile,
    useFile,
    comment,
    performer,
    thumbnail,
    restricted,
    projects,
    project3D,
  }: AssetFormType) => {
    const selectedTagIds = extractSelectedTagsIds(tags);

    await mutateAsync({
      id: Number(assetId),
      name,
      media_url: mediaUrl,
      ai_prompt: aiPrompt,
      customer,
      source_file: sourceFile,
      use_file: useFile,
      comment,
      performer,
      thumbnail,
      restricted,
      projects,
      project_3d_url: project3D,
      tags: selectedTagIds,
    });
  };

  const discardChanges = () => {
    reset();
  };
  console.log(isPending);
  return (
    <Layout childrenContainerClasses={{ padding: '24px 0 40px 0' }}>
      <Titled title={(title) => `Update asset | ${title}`} />
      <Container
        sx={(t) => ({
          display: 'flex',
          flexDirection: 'column',
          [t.breakpoints.up('md')]: {
            flexDirection: 'row',
          },
          [t.breakpoints.down('md')]: {
            px: '16px !important',
          },
        })}
        maxWidth={false}
      >
        <Suspense fallback={<CircularSpinner containerSx={{ height: '100vh' }} />}>
          <Grid container item xs={12} md={6} sx={{ pr: 3 }} display="flex" flexDirection="column" alignItems="center">
            <Box
              sx={(t) => ({
                display: 'flex',
                justifyContent: 'center',
                width: 420,
                maxHeight: 420,
                [t.breakpoints.up('xl')]: {
                  width: 700,
                  maxHeight: 700,
                },
              })}
            >
              <AssetContentView thumbnail={thumbnail} type={getAssetTypeFromUrl(url)} url={url} />
            </Box>
          </Grid>
          <Grid container item xs={12} md={6} display="flex" flexDirection="column">
            <Typography variant="h3" sx={{ mb: 2.5 }}>
              Edit asset
            </Typography>
            <FormProvider {...formMethods}>
              <AssetForm />
            </FormProvider>
            <CreateUpdateAssetActionBlock
              onSubmit={handleSubmit(onSubmit)}
              onDiscard={discardChanges}
              isSubmitting={isPending}
            />
          </Grid>
        </Suspense>
      </Container>
    </Layout>
  );
};

export default UpdateAsset;
