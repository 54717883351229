import { TextField, InputAdornment } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import type { FC } from "react";
import { useMemo, useState } from "react";
import useUsersListParams from "../hooks/useUsersListParams";
import { debounce } from "lodash-es";

const UsersEmailFilter: FC = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { setQueryString } = useUsersListParams();
  const updateQueryValue = useMemo(() => debounce(setQueryString, 700), [setQueryString]);
  const handleSearchValueChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
    updateQueryValue(value.length ? value : undefined);
  };

  return (
    <TextField
      size="small"
      fullWidth
      placeholder="Search users"
      name="searchValue"
      onChange={handleSearchValueChange}
      value={searchValue}
      inputProps={{ style: { paddingTop: 0, paddingBottom: 0, }, maxLength: 120 }}
      InputProps={{
        sx: {
          height: 40,
        },
        endAdornment: (
          <InputAdornment sx={{ width: 22 }} position="end">
            {isFocused ? <SearchIcon /> : null}
          </InputAdornment>
        ),
        onFocus: () => { setIsFocused(true); },
        onBlur: () => { setIsFocused(false); },
      }}
    />
  );
};

export default UsersEmailFilter;
