import { BooleanParam, NumericArrayParam, StringParam, createEnumParam, useQueryParam, withDefault } from 'use-query-params';
import type { SortingOptionValue } from '../api';

const SORTING_VALUES = createEnumParam<SortingOptionValue>(["newest", "oldest"]);

const useAssetsListParams = () => {
  const [sorting, setSorting] = useQueryParam('sorting', withDefault(SORTING_VALUES, "newest"));
  const [favourite, setFavourite] = useQueryParam('favourite', withDefault(BooleanParam, false));
  const [tags, setTags] = useQueryParam('tags', withDefault(NumericArrayParam, undefined));
  const [q, setQ] = useQueryParam('q', withDefault(StringParam, undefined));

  return {
    sorting: sorting ?? "newest",
    setSorting,

    favourite,
    setFavourite,

    tags,
    setTags,

    q,
    setQ,
  };
};

export default useAssetsListParams;
