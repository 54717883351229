import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AudioImage from '../../../assets/audio-asset.jpg';
import type { AssetTypeValue } from '../api';

interface AssetContentViewProps {
  type: AssetTypeValue | null;
  url: string;
  thumbnail?: string;
}

const AssetContentView: FC<AssetContentViewProps> = ({ type, url, thumbnail }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const onPlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const onPause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (videoRef.current) {
      video?.addEventListener('play', onPlay);
      video?.addEventListener('pause', onPause);
    }

    return () => {
      video?.removeEventListener('play', onPlay);
      video?.removeEventListener('pause', onPause);
    };
  }, [onPause, onPlay, videoRef]);

  const handlePlay = async () => {
    return videoRef.current?.play();
  };

  if (!type) return null;

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      {type === 'video' && (
        <>
          {!isPlaying && (
            <IconButton
              onClick={handlePlay}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 60,
                transform: 'translate(-50%, -50%)',
                color: 'white',
                width: 100,
                height: 50,
                fontSize: 50,
              }}
            >
              <PlayCircleOutlineIcon sx={{ fontSize: 100 }} />
            </IconButton>
          )}
          <Box
            component="video"
            id="video"
            sx={{ position: 'relative', zIndex: 50 }}
            ref={videoRef}
            width="100%"
            height="100%"
            controls
            muted
          >
            <source src={url} type="video/mp4" />
          </Box>
        </>
      )}
      {type === 'image' && <Box component="img" width="100%" src={url} />}
      {type === 'track' && (
        <>
          <Box component="img" width="100%" src={thumbnail ?? AudioImage} />
          <Box component="audio" controls sx={{ width: '100%' }}>
            <source type="audio/wav" src={url} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AssetContentView;
