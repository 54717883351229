/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }

  export interface ShapeOverrides {
    borderRadius: {
      sm: 2;
      md: 4;
    };
  }

  export interface Theme {
    borderRadius: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
    };
  }

  export interface ThemeOptions {
    borderRadius?: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
    };
  }
}

export const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  borderRadius: {
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '16px',
  },
  palette: {
    grey: {
      50: '#ECEFF1',
      100: 'rgba(0, 0, 0, 0.15)',
    },

    primary: {
      main: '#212121',
      dark: '#303030',
    },

    info: {
      main: '#F1F1F1',
    },

    text: {
      primary: 'rgba(0,0,0, .87)',
      secondary: 'rgba(0,0,0, .60)',
      disabled: 'rgba(0,0,0, .38)',
    },
  },
  typography: {
    h1: {
      fontSize: '48px',
      fontWeight: 400,
    },
    h2: {
      fontSize: '34px',
      fontWeight: 400,
    },
    h3: {
      fontSize: '24px',
      fontWeight: 400,
    },
    h4: {
      fontSize: '20px',
      fontWeight: 400,
    },
    h5: {
      fontSize: '20px',
      fontWeight: 400,
    },
    h6: {
      fontSize: '20px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.25,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.13,
    },
    caption: {
      fontSize: '0.813rem',
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
      },
    },
    MuiButton: { styleOverrides: { root: { boxShadow: 'none', '&:hover': { boxShadow: 'none' } } } },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: '56px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          [`& .MuiInputLabel-root`]: {
            fontSize: '0.875rem',
            lineHeight: '1.4rem',
          },
          [`& .MuiInputBase-root`]: {
            minHeight: 40,
          },
        },
        option: {
          padding: '0!important',
          fontSize: '0.875rem',
          lineHeight: '1.4rem',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          [`&`]: {
            width: '100%',
          },
          [`& .MuiInputLabel-root`]: {
            fontSize: '0.875rem',
            lineHeight: '1.4rem',
          },
          [`& .MuiInputBase-root`]: {
            minHeight: 40,
          },
        },
        select: {
          [`&`]: {
            minWidth: 120,
            minHeight: 40,
            padding: '0px 4px 0px 12px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          fontSize: '0.875rem',
          lineHeight: '1.4rem',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: theme.palette.grey[100],
            },
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '0.875rem',
          lineHeight: '1.4rem',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          mx: 2,
          [theme.breakpoints.up('md')]: {
            mx: 3,
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          paddingLeft: '12px',
          paddingRight: '12px',
          paddingTop: '4px',
          paddingBottom: '4px',
          minHeight: '36px !important',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '20px !important',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          padding: '6px 8px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          fontSize: theme.typography.body2.fontSize,
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 32,
          height: 20,
          padding: 0,
          [`.Mui-checked`]: {
            transform: 'translateX(12px) !important',
          },
        },
        switchBase: {
          top: 2,
          left: 2,
          padding: 0,
          width: 16,
          height: 16,
        },
        thumb: {
          width: 16,
          height: 16,
          background: 'white',
        },
        track: ({ _, theme }) => ({
          borderRadius: 14,
          backgroundColor: theme.palette.grey[400],
          opacity: '1 !important',
        }),
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          backgroundColor: theme.palette.grey[300],
        }),
        bar: {
          background: `linear-gradient(90deg, #4BD1FC 0%, #A7ABF5 16.6%, #7F57F2 33.85%, #F62AB9 54.17%, #FDA680 84.86%, #F8DA70 100%)`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ _, theme }) => ({
          borderRadius: theme.borderRadius.sm,
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          boxShadow: 'unset',
          '&::before': { display: 'none' },
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 8px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ _, theme }) => ({
          fontSize: theme.typography.body2.fontSize,
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '0.925em',
          height: '0.925em',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ _, theme: t }) => ({
          borderRadius: t.borderRadius.sm,
          backgroundColor: t.palette.primary.main,
          marginRight: 8,
          height: 24,
          '&': {
            borderRadius: t.borderRadius.sm,
            backgroundColor: t.palette.primary.main,
            height: 24,
            marginRight: 6,
            marginBottom: 6,
          },

          '& .MuiChip-deleteIcon:hover': {
            color: 'white',
            opacity: '0.9',
          },
        }),
        label: ({ _, theme: t }) => ({
          color: 'white',
          fontSize: t.typography.caption.fontSize,
          paddingLeft: 8,
          paddingRight: 8,
        }),
        deleteIcon: {
          color: 'white',
          opacity: '0.7',
          fontSize: 18,
          marginLeft: 1,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          px: 0,
          justifyContent: 'flex-start',
        }),
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
};

const theme = createTheme(themeOptions);

export default theme;
