import type { FC } from "react";
import { useCallback, useMemo, useState } from "react"
import Dialog from '@mui/material/Dialog';
import type { AssetEntry } from "../api";
import {
  AssetChip,
  AssetDownloadFileButton,
  AssetPreviewError,
  AssetPreviewImage,
  AssetPreviewVideo,
} from "./AssetsListComponents";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { ArrowBack, ArrowForward, Close, ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { AssetItemListTags } from "./AssetTagsComponents";
import getAssetType from "../utils/getAssetType";
import { debounce } from "lodash-es";
import { useAssets } from "..";
import type { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import useEnqueueSnackbar from "hooks/useEnqueueSnackbar";
import AssetAddToFavourites from "./AssetAddToFavourites";

interface AssetPreviewProps {
  assetsList: AssetEntry[];
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult<{count: number; data: AssetEntry[]}, unknown>>;
};

const AssetPreviewPopup: FC<AssetPreviewProps> = ({
  assetsList,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage
}) => {
  const { handleToggleAssetPreview, assetPreviewId: assetId, isOpenPreview: isOpen } = useAssets();
  const [isError, setIsError] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(() => assetsList.findIndex((value) => value.id === assetId));
  const { onShowAlert } = useEnqueueSnackbar();

  const handlePrevClick = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      setIsError(false);
    }
  }, [currentIndex, setCurrentIndex]);

  const handleNextClick = useCallback(async () => {
    if (currentIndex === assetsList.length - 1) {
      await fetchNextPage().catch(console.error);
      setIsError(false);
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        if (assetsList[nextIndex]) return nextIndex;
        return prevIndex;
      });
      return
    }

    if (currentIndex < assetsList.length - 1) {
      setIsError(false);
      setCurrentIndex((prevIndex) => prevIndex + 1);
      return
    }
  }, [currentIndex, assetsList, setCurrentIndex, fetchNextPage]);

  const handleAssetPreview = useCallback(() => {
    handleToggleAssetPreview();
  }, [handleToggleAssetPreview]);

  const handleCopyValue = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      onShowAlert('Copied');
    } catch (err) {
      onShowAlert('Error occurs');
    }
  };

  const debouncePrevClick = useMemo(() => debounce(handlePrevClick, 300), [handlePrevClick]);
  const debounceNextClick = useMemo(() => debounce(handleNextClick, 300), [handleNextClick]);

  return useMemo(() => {
    const { currentAsset, isTrack, isVideo, isPhoto } = {
      currentAsset: assetsList[currentIndex],
      ...getAssetType({ asset_type: assetsList[currentIndex].asset_type, }),
    };

    return (
      <Dialog fullScreen open={isOpen} onClose={handleAssetPreview}>
        <DialogTitle component={Box} sx={{ display: 'flex', justifyContent: 'space-between', p: 4, }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', ['&:hover .toggle-visibility']: { visibility: 'visible', }}}>
            <Typography
              component={Link}
              variant="h2"
              to={`/assets/${currentAsset.id}`}
              sx={ t => ({ color: t.palette.primary.main, mb: 2, ['&:hover']: { textDecoration: 'none', }, })}>{currentAsset.name}</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center',  visibility: 'hidden', }} className={'toggle-visibility'}>
                <Button
                  startIcon={<ContentCopyIcon fontSize="small" />}
                  sx={{ textTransform: 'capitalize', mr: 2, borderRadius: '4px', }}
                  onClick={() => handleCopyValue(currentAsset.name)}>Name</Button>
                <Button
                  startIcon={<ContentCopyIcon fontSize="small" />}
                  sx={{ textTransform: 'capitalize', borderRadius: '4px', }}
                  onClick={() => handleCopyValue(`${location.href}/${currentAsset.id}`)}>Link</Button>
              </Box>
          </Box>
          <IconButton onClick={handleAssetPreview} sx={{ maxWidth: 42, maxHeight: 42, }}>
            <Close sx={t => ({ fontSize: 32, color: t.palette.grey[600], })} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 4, width: '100%', }}>
          <Box sx={t => ({
            display: 'flex',
            flexGrow: 1,
            width: '100%',
            height: '100%',
            [t.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          })}>
            <Box
              sx={t => ({
                display: 'flex',
                flexGrow: 1,
                [t.breakpoints.up('md')]: {
                  minWidth: '25%',
                  maxWidth: 200,
                },
                [t.breakpoints.down('md')]: {
                  width: '100%',
                },
              })}>
              <Box>
                <AssetChip label={currentAsset.format} variant="filled" sx={{ textTransform: 'uppercase', }} />
                <AssetItemListTags tags={currentAsset.tags} />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
              }}>
              {!isError ? (
                <Box
                  sx={t => ({
                    maxWidth: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',

                    [t.breakpoints.down('md')]: {
                      my: 2,
                    },
                  })}>
                  {isPhoto && <AssetPreviewImage
                    sx={{ maxHeight: '100%', }}
                    src={currentAsset.thumbnail}
                    onError={() => { setIsError(true); }} />}
                  {isVideo && (
                    <AssetPreviewVideo onError={() => { setIsError(true); }} key={currentAsset.media_url} controls muted autoFocus autoPlay>
                      <source src={currentAsset.media_url} type="video/mp4" />
                    </AssetPreviewVideo>
                  )}
                  {isTrack && (
                    <AssetPreviewVideo onError={() => { setIsError(true); }} key={currentAsset.media_url} poster={currentAsset.thumbnail} controls autoFocus autoPlay>
                      <source src={currentAsset.media_url} type="video/mp4" />
                    </AssetPreviewVideo>
                  )}
                </Box>
              ) : <AssetPreviewError assetType={currentAsset.asset_type} showTitle />}
            </Box>
            <Box sx={t => ({
              display: 'flex',
              flexGrow: 1,
              [t.breakpoints.up('md')]: {
                minWidth: '25%',
                maxWidth: 200,
                justifyContent: 'end',
              },
              [t.breakpoints.down('md')]: {
                width: '100%',
                alignItems: 'start',
                justifyContent: 'center',
              },
            })}>
              <Box
                sx={t => ({
                  display: 'flex',
                  [t.breakpoints.up('md')]: {
                    width: 36,
                    flexDirection: 'column',
                  },
                  [t.breakpoints.down('md')]: {
                    flexDirection: 'row',
                  },
                })}>
                <Box sx={{ mb: 1, }}>
                  <AssetAddToFavourites isPreview assetId={currentAsset.id} favourite={currentAsset.favourite} />
                </Box>
                <AssetDownloadFileButton isPreview file={currentAsset.source_file} fileName={currentAsset.name} />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 4, justifyContent: 'space-between', }}>
          <Button
            onClick={debouncePrevClick}
            sx={{ ml: -1, fontSize: 26, fontWeight: 400, textTransform: 'capitalize', }}
            startIcon={<ArrowBack sx={{ fontSize: '32px !important', }} />}
            disabled={!currentIndex || isFetchingNextPage}>
            Prev
          </Button>
          <Button
            onClick={debounceNextClick}
            sx={{ mr: -1, fontSize: 26, fontWeight: 400, textTransform: 'capitalize', }}
            endIcon={isFetchingNextPage ? <CircularProgress size={22} /> : <ArrowForward sx={{ fontSize: '32px !important', }} />}
            disabled={(!hasNextPage && currentIndex === assetsList.length - 1) || isFetchingNextPage}>
            Next
          </Button>
        </DialogActions>
      </Dialog>
    )
  }, [assetsList, currentIndex, hasNextPage, isFetchingNextPage, isError, isOpen, handleToggleAssetPreview, handleAssetPreview, handleCopyValue, debounceNextClick, debouncePrevClick])
};

export default AssetPreviewPopup;
