import { useState, type FC, useCallback } from 'react';
import type { TagEntry } from '../api';
import { AssetChip, AssetChipLabel } from './AssetsListComponents';
import { Box, Button } from '@mui/material';

const AssetTagEntry: FC<{ tag: TagEntry }> = ({ tag }) => (
  <AssetChip
    label={<AssetChipLabel label={tag.tag_name} />}
    variant="filled"
    sx={(t) => ({ backgroundColor: t.palette.grey[200], p: 0.25, mb: 0.25, maxWidth: 160 })}
  />
);

const AssetTagVisibility: FC<{ value: string; handle: () => void }> = ({ value, handle }) => (
  <Button sx={{ fontSize: 12, height: 22 }} variant="text" onClick={handle}>
    {value}
  </Button>
);

export const AssetItemListTags: FC<{ tags: TagEntry[]; className?: string }> = ({ tags, className }) => {
  const visibleTags = tags.slice(0, 9);
  const additionalTags = tags.slice(9, tags.length);
  const [showAdditional, setShowAdditional] = useState(false);

  const handleSetIsShowAdditional = useCallback(() => {
    setShowAdditional((prev) => !prev);
  }, [setShowAdditional]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} className={className}>
      {visibleTags.map((tag: TagEntry) => (
        <AssetTagEntry key={`${tag.tag_id}.${tag.tag_id}`} tag={tag} />
      ))}
      {!showAdditional && additionalTags.length ? (
        <AssetTagVisibility value="Show more" handle={handleSetIsShowAdditional} />
      ) : null}

      {showAdditional && additionalTags.length
        ? additionalTags.map((tag: TagEntry) => <AssetTagEntry key={`${tag.tag_id}.${tag.tag_name}`} tag={tag} />)
        : null}
      {showAdditional && additionalTags.length ? (
        <AssetTagVisibility value="Show less" handle={handleSetIsShowAdditional} />
      ) : null}
    </Box>
  );
};
