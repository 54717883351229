import { useBreakpoints, useModal } from 'hooks';
import { useState, type FC, type ReactNode, useMemo, useCallback, useRef } from 'react';
import type { ListType } from './AssetsContext';
import { AssetsContext } from './AssetsContext';
import { getPersistedData, persistData } from 'utils/persist';

interface AuthContextProviderProps {
  children: ReactNode;
};

const AssetsContextProvider: FC<AuthContextProviderProps> = ({ children }) => {
  const { isBreakpoint } = useBreakpoints('sm');
  const { isOpen: isVisibleFilter, toggle: handleVisibleFilter } = useModal(!isBreakpoint);
  const { toggle: onToggleAssetPreview, isOpen: isOpenPreview } = useModal();
  const [assetPreviewId, setAssetPreviewId] = useState<number | undefined>(undefined);
  const [countAssets, setCountAssets] = useState<number | null>(null);
  const [listType, setListType] = useState<ListType>(() => {
    if (isBreakpoint) return 'row';

    const persistedValue = getPersistedData<ListType>('listType');
    return persistedValue ?? 'row';
  });

  const handleListType = useCallback((value: ListType) => {
    window.scroll(0, 0);
    setListType(value);
    persistData('listType', value);
  }, [setListType]);

  const handleToggleAssetPreview = useCallback((id?: number) => {
    setAssetPreviewId(id);
    onToggleAssetPreview();
  }, [setAssetPreviewId, onToggleAssetPreview]);

  const handleSetCountAssets = useCallback((value: number) => {
    setCountAssets(value);
  }, [setCountAssets]);

  const onResetValuesCallbackRef = useRef(() => {
    // use to store a reference to a function
  });

  const contextValue = useMemo(
    () => ({
      isVisibleFilter,
      handleVisibleFilter,

      listType,
      handleListType,

      isGrid: listType === 'row' || isBreakpoint,
      isList: listType === 'column' && !isBreakpoint,

      handleToggleAssetPreview,
      isOpenPreview,
      assetPreviewId,

      countAssets,
      handleSetCountAssets,

      onResetValuesCallback: onResetValuesCallbackRef,

    }),
    [
      isVisibleFilter,
      handleVisibleFilter,
      listType,
      handleListType,
      handleToggleAssetPreview,
      isOpenPreview,
      assetPreviewId,
      isBreakpoint,
      countAssets,
      handleSetCountAssets,
      onResetValuesCallbackRef,
    ]
  );

  return <AssetsContext.Provider
    value={contextValue}>
    {children}
  </AssetsContext.Provider>;
};

export default AssetsContextProvider;
