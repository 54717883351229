import { Grid } from '@mui/material';
import MediaBlock from './MediaBlock';
import MediaDescription from './MediaDescription';
import ViewAssetActionBlock from './ViewAssetActionBlock';
import type { FC } from 'react';

const AssetDetails: FC = () => {
  return (
    <Grid
      container
      sx={(t) => ({
        position: 'relative',
        flexDirection: 'row',
        [t.breakpoints.down('md')]: { flexDirection: 'column' },
      })}
    >
      <ViewAssetActionBlock />
      <Grid xs={12} md={6} item display="flex" justifyContent="center" alignItems="flex-start">
        <MediaBlock />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={6}
        sx={(t) => ({ [t.breakpoints.up('md')]: { pl: 3 }, mb: 7 })}
        direction="column"
      >
        <MediaDescription />
      </Grid>
    </Grid>
  );
};

export default AssetDetails;
