import { Box, Typography } from "@mui/material";
import type { TagEntry, TagGroupEntry} from "features/assets";
import { useTagGroups } from "features/assets";
import { type FC, useMemo, useCallback } from "react";
import { AssetNoResults } from "../AssetsListComponents";
import Checkbox from "components/common/Checkbox";

const TagItem: FC<{tag: TagGroupEntry}> = ({ tag }) => {
  const tagMapper = useCallback((t: TagEntry) => (
    <Checkbox key={`${t.tag_id}:${t.tag_name}`} name={'searchTags.' + t.tag_id} label={t.tag_name} />
  ), []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
      <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 36, backgroundColor: 'white', zIndex: 3, position: 'sticky', top: '-16px', left: 0, px: 1, mx: -1,  }}>
        <Typography
          sx={t => ({
            fontSize: t.typography.body2.fontSize,
            lineHeight: t.typography.body2.lineHeight,
            fontWeight: 500,
          })}>{tag.group_name}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', }}>
      {tag.tags.map(tagMapper)}
      </Box>
    </Box>
  )
};

const AssetsSearchResultTags: FC<{q: string}> = ({ q }) => {
  const { data: { data } } = useTagGroups({
    params: { q },
  });

  const sortedData = useMemo(() => data.sort((a, b) => a.tags.length - b.tags.length), [data]);

  return (
    <Box sx={t => ({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      py: 2,
      px: 1,
      overflow: 'hidden',
      overflowY: 'scroll',
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: sortedData.length ? `${t.palette.grey[300]}` : 'transparent',
      })}>
      {sortedData.length ? <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 30, mb: 1, }}>
        <Typography
          sx={t => ({
            fontSize: t.typography.body1.fontSize,
            lineHeight: t.typography.body1.lineHeight,
            color: t.palette.grey[600],
            fontWeight: 400,
          })}>Similar tags</Typography>
      </Box> : null}
      <Box sx={{
        display: 'grid',
        gridColumnGap: '16px',
        gridRowGap: '0',
        gridTemplateColumns: 'repeat(3, 1fr)',
        width: '100%',
        maxHeight: 300,
      }}>
        {sortedData.length
          ? sortedData.map((tag: TagGroupEntry) => <TagItem key={`tagGroupResult.${tag.group_id}:${tag.group_name}`} tag={tag}/> )
          : <AssetNoResults text="No similar tags" />}
      </Box>
    </Box>
  )
}

export default AssetsSearchResultTags;
