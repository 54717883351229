import { useCallback, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { useAssetsList } from "../api";
import { useAssets } from "../context";
import { useAssetsListParams } from "../hooks";
import AssetItem from "./AssetItem";
import { AssetListContainer, AssetNoResults } from "./AssetsListComponents";
import AssetPreviewPopup from "./AssetPreviewPopup";


const AssetsList = () => {
  const { listType, isOpenPreview, handleSetCountAssets, countAssets } = useAssets();
  const { sorting, favourite, tags, q } = useAssetsListParams();

  const { hasNextPage, isFetchingNextPage, fetchNextPage, data: {count: countAssetsFetched, data: assetsList}, } =
    useAssetsList({
      params: { sorting, favourite, tags: tags as number[], q, },
    });

  const handleScroll = useCallback(() => {
    if (!hasNextPage || isFetchingNextPage) return;

    if (((window.innerHeight + window.scrollY) + 100) >= document.documentElement.scrollHeight) {
      fetchNextPage().catch(console.error);
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if( countAssets !== countAssetsFetched ) {
      handleSetCountAssets(countAssetsFetched);
    }
  }, [countAssets, countAssetsFetched, handleSetCountAssets]);

  const AssetsListMemo = useMemo(() => (
      assetsList.length
        ? assetsList.map((asset, i) => (<AssetItem key={`${i}.${asset.id}.${listType}`} asset={asset} />))
        : <AssetNoResults />
    ), [assetsList, listType]);


  return (
    <Box sx={{ display: 'flex', flexGrow: 1, py: 2, flexDirection: 'column', }}>
      <AssetListContainer>
        {AssetsListMemo}
        {isOpenPreview && <AssetPreviewPopup
          assetsList={assetsList}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
        />}
      </AssetListContainer>
    </Box>
  );
};

export default AssetsList;
