import { Box, List, Drawer } from '@mui/material';
import { useEffect, type FC } from 'react';
import LogoAdam from 'assets/logo-adam-grey.svg?react';
import { InsertPhotoOutlined as InsertPhotoOutlinedIcon } from '@mui/icons-material';
import SidebarNavItem from './SidebarNavItem';
import SettingsNav from './SettingsNav';
import UserNav from './UserNav';
import { useModal } from 'hooks';
import TopBar from './TopBar';
import { useLocation } from 'react-router';
import { useBreakpoints } from 'hooks';

const Sidebar: FC = () => {
  const location = useLocation();
  const { isOpen, open, close, toggle } = useModal();
  const { isBreakpoint } = useBreakpoints('sm');

  useEffect(() => {
    isBreakpoint ? close() : open();
  }, [isBreakpoint, close, open]);

  useEffect(() => {
    close();
  }, [location, close]);

  return (
    <>
      {isBreakpoint && <TopBar onToggleMenu={toggle} />}
      <Drawer
        anchor={'left'}
        open={isOpen}
        onClose={close}
        variant={isBreakpoint ? 'temporary' : 'permanent'}
        elevation={0}
        sx={(t) => ({
          position: 'relative',
          zIndex: 7,
          flexShrink: 0,
          width: 80,
          [`& .MuiDrawer-paper`]: {
            position: isBreakpoint ? 'fixed' : 'relative',
            borderRightColor: isBreakpoint ? 'transparent' : t.palette.grey[50],
            overflow: 'hidden',
          },
          [`& .MuiModal-backdrop`]: {
            backgroundColor: 'rgba(43, 43, 43, 0.20)',
          },
        })}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          component="nav"
          sx={(t) => ({
            position: isBreakpoint ? 'relative' : 'fixed',display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column',
            flexGrow: 1,
            maxWidth: 80,
            width: 80,
            pt: isBreakpoint ? 0 : 3,
            pb: isBreakpoint ? 2 : 3,
            height: '100vh',
            backgroundColor: t.palette.grey['50'],
            overflow: 'hidden',
          })}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            {!isBreakpoint && <LogoAdam width={56} height={30} style={{ marginBottom: '12px' }} />}
            <List sx={{ px: 0, pt: 0, display: 'flex', flexGrow: 1 }}>
              <SidebarNavItem
                to="/assets"
                title="Assets"
                icon={<InsertPhotoOutlinedIcon style={{ fontSize: 24, padding: 0 }} />}
              />
            </List>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <SettingsNav />
            <UserNav />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
