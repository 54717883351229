import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import copy from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useModal } from '../../../hooks';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router';
import { useAsset, useChangeFavourites } from '../api';
import { Link } from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
const MediaDescription = () => {
  const queryClient = useQueryClient();
  const { open: addNameHover, close: removeNameHover, isOpen: isNameHovered } = useModal();
  const { toggle: toggleAiPrompt, isOpen: isAIPromptOpen } = useModal();
  const { assetId } = useParams();
  const { mutateAsync: changeFavourite, isPending } = useChangeFavourites({
    config: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['assets', assetId] });
      },
    },
  });

  const { data: asset } = useAsset({ id: Number(assetId) });

  const handleDownload = (link: string) => {
    const ext = link.split('.').pop();

    saveAs(link, asset.name + '.' + ext);
  };

  const assetIcon = useMemo(() => {
    if (isPending) {
      return <CircularProgress size={20} sx={{ color: 'black' }} />;
    }

    return asset.favourite ? <StarRateIcon /> : <StarBorderIcon />;
  }, [asset.favourite, isPending]);

  return (
    <>
      <Box onMouseOver={addNameHover} onMouseLeave={removeNameHover} sx={{ mb: 4 }}>
        <Typography variant="h3" mb={0.5}>
          {asset.name}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2.5 }}>
          <Button
            onClick={() => changeFavourite({ asset_id: Number(assetId), favourite: !asset.favourite })}
            sx={{ textTransform: 'uppercase' }}
            startIcon={assetIcon}
            variant="text"
          >
            favourite
          </Button>
          {isNameHovered && (
            <>
              <Button
                sx={{ textTransform: 'capitalize' }}
                onClick={() => copy(asset.name)}
                startIcon={<ContentCopyIcon />}
                variant="text"
              >
                name
              </Button>
              <Button
                sx={{ textTransform: 'capitalize' }}
                onClick={() => copy(window.location.toString())}
                startIcon={<ContentCopyIcon />}
                variant="text"
              >
                link
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, pb: 4, mb: 4, borderBottom: '1px solid rgba(0,0,0,0.12)' }}
      >
        {asset.tags.map(({ id, name }) => (
          <Chip
            sx={(t) => ({
              borderRadius: t.borderRadius.xs,
            })}
            size="small"
            color="default"
            key={id}
            label={name}
          />
        ))}
      </Box>
      <Box sx={{ pb: 4, mb: 4, borderBottom: '1px solid rgba(0,0,0,0.12)' }}>
        <Box sx={{ display: 'flex', gap: 1, mb: 2.5 }}>
          <Button
            startIcon={<DownloadIcon />}
            variant="contained"
            color="inherit"
            onClick={() => {
              handleDownload(asset.use_file);
            }}
            sx={{ textTransform: 'uppercase', boxShadow: 'none' }}
          >
            get use file
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            variant="contained"
            color="inherit"
            onClick={() => {
              handleDownload(asset.source_file);
            }}
            sx={{ textTransform: 'uppercase', boxShadow: 'none' }}
          >
            get source file
          </Button>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {asset.project_3d_url && (
            <Button
              startIcon={<OpenInNewIcon />}
              target="_blank"
              component={Link}
              to={asset.project_3d_url}
              sx={{ textTransform: 'uppercase' }}
            >
              3d project
            </Button>
          )}
          <Button
            startIcon={<OpenInNewIcon />}
            sx={{ textTransform: 'uppercase' }}
            target="_blank"
            component={Link}
            to={asset.media_url}
          >
            media url
          </Button>
        </Box>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" mb={1}>
          Comment
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {asset.comment}
        </Typography>
      </Box>
      {asset.ai_prompt && (
        <Box sx={{ mb: 4 }}>
          <Accordion
            disableGutters
            sx={{
              boxShadow: 'unset',
              '&::before': { display: 'none' },
            }}
            expanded={isAIPromptOpen}
            onChange={toggleAiPrompt}
          >
            <AccordionSummary
              sx={{
                px: 0,
                justifyContent: 'flex-start',
                '.MuiAccordionSummary-content': { flexGrow: 0 },
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="ai-prompt"
              id="ai-prompt"
            >
              <Typography sx={{ flexShrink: 0, mr: 1 }}>AI prompt</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {asset.ai_prompt}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      <List>
        <ListItem sx={{ px: 0 }}>
          <Typography sx={{ width: 120, mr: 2 }} variant="subtitle1">
            Created for
          </Typography>
          <Typography variant="body1">{asset.projects.map((p) => p.name).join(', ')}</Typography>
        </ListItem>
        <Divider />
        <ListItem sx={{ px: 0 }}>
          <Typography sx={{ width: 120, mr: 2 }} variant="subtitle1">
            Access
          </Typography>
          <Typography variant="body1">{asset.restricted ? 'Public' : 'Private'}</Typography>
        </ListItem>
        <Divider />
        <ListItem sx={{ px: 0 }}>
          <Typography sx={{ width: 120, mr: 2 }} variant="subtitle1">
            Customer
          </Typography>
          <Typography variant="body1">{asset.customer}</Typography>
        </ListItem>
        <Divider />
        <ListItem sx={{ px: 0 }}>
          <Typography sx={{ width: 120, mr: 2 }} variant="subtitle1">
            Performer
          </Typography>
          <Typography variant="body1">{asset.performer}</Typography>
        </ListItem>
        <Divider />
      </List>
    </>
  );
};

export default MediaDescription;
