/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, TableCell, TableRow, Typography } from '@mui/material';
import { EditOutlined as EditIcon } from '@mui/icons-material';
import type { FC } from 'react';
import type { UserAccessLevel, UserEntry, UserProject } from '../api';
import { Box } from '@mui/system';
import UsersDelete from './UsersDelete';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import useMyAccess, { PROJECT_DEPENDANT_ROLES } from '../hooks/useMyAccess';
import { isEmpty } from 'lodash-es';

const getUserProjects = (accessLevel: UserAccessLevel | null, projects: UserProject[] | [] | undefined) => {
  if (accessLevel && !PROJECT_DEPENDANT_ROLES.some((r) => r === accessLevel)) return 'All projects';
  return projects?.length ? projects.map((prj) => prj.name).join(', ') : '';
};

const UsersTableRowItem: FC<{ user: UserEntry }> = ({ user }) => {
  const [_, setUserId] = useQueryParam('userId', withDefault(NumberParam, null));
  const { hasUserChangeAccess } = useMyAccess();

  return (
    <TableRow hover sx={{ '&:hover td:last-child div': { visibility: hasUserChangeAccess ? 'visible' : 'hidden' } }}>
      <TableCell style={{ paddingLeft: '4px' }}>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {user.email}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{user.access_level}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {getUserProjects(user.access_level, user.projects)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {user.customer_tag}
        </Typography>
      </TableCell>
      <TableCell>
        <Box sx={{ visibility: 'hidden' }}>
          <Button
            onClick={() => {
              setUserId(user.id);
            }}
            variant="text"
            color="primary"
            startIcon={<EditIcon color="primary" sx={{ fontSize: 20 }} />}
            sx={{ mr: 1 }}
          >
            Edit user
          </Button>
          <UsersDelete user={user} />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default UsersTableRowItem;
