import type { AssetTypeValue } from '../api';

const getAssetTypeFromUrl = (url?: string): AssetTypeValue | null => {
  if (!url) return null;
  const ext = url.split('.').pop();

  switch (ext) {
    case 'mp4':
      return 'video';
    case 'mp3':
      return 'track';
    default:
      return 'image';
  }
};

export default getAssetTypeFromUrl;
