import type { CircularProgressProps, SxProps } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import type { FC } from 'react';

interface CircularSpinnerProps {
  spinnerProps?: CircularProgressProps;
  containerSx?: SxProps;
}
const CircularSpinner: FC<CircularSpinnerProps> = ({ spinnerProps, containerSx }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...containerSx,
      }}
    >
      <CircularProgress {...spinnerProps} />
    </Box>
  );
};

export default CircularSpinner;
