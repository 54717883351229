import { Search } from "@mui/icons-material";
import { Box, Button, Skeleton, styled } from "@mui/material";
import type { FC } from "react";

export const AssetsSearchContainer = styled(Box)<{isShowAssetsSearch: boolean}>(({ theme: t, isShowAssetsSearch }) => (
  `
  display: flex;
  width: 100%;
  min-height: 40px;
  max-height: 40px;

  ${t.breakpoints.up(900)} {
    max-width: ${isShowAssetsSearch ? '870px' : '420px'};
  }

  ${t.breakpoints.down(900)} {
    max-width: ${isShowAssetsSearch ? '870px' : '40vw'};
  }

  ${t.breakpoints.up('sm')} {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  ${t.breakpoints.down('sm')} {
    position: fixed;
    top: 8px;
    right: 12px;
    z-index: 9;
    max-width: ${isShowAssetsSearch ? `100%` : `calc(100% - 180px)`};
  };
  `
));

export const AssetsSearchPositionContainer = styled(Box)<{isShowAssetsSearch: boolean}>(({ theme: t, isShowAssetsSearch }) => (
  `
  z-index: 10;
  position: absolute;
  top: ${isShowAssetsSearch ? '-10px' : 0};
  left: 0;
  width: 100%;
  margin: auto;
  background-color: ${isShowAssetsSearch ? 'white' : 'transparent'};

  ${t.breakpoints.up('sm')} {
    right: ${isShowAssetsSearch ? '0' : 0};
    display: flex;
    flex-direction: column;
    padding: ${isShowAssetsSearch ? `12px` : 0};
    border-radius: ${t.borderRadius.md};
  };
  ${t.breakpoints.down('sm')} {
    right: ${isShowAssetsSearch ? '-24px' : 0};
    padding: ${isShowAssetsSearch ? `12px` : 0};
    height: ${isShowAssetsSearch ? '100vh' : 'auto'};
    align-items: start;
    overflow: hidden;
  };
  `
));

export const AssetsSearchButton = styled(Button)(({ theme: t }) => (
  `
  width: 120px;
  padding: 0px;
  margin-left: 12px;

  ${t.breakpoints.up('sm')} {
    border-radius: 24px;
  };
  ${t.breakpoints.down('sm')} {
    position: fixed;
    z-index: 5;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 56px;
    border-radius: 28px;
    border: 6px solid #fff;
    box-shadow: ${t.shadows[10]};
  };
  `
));

export const AssetsSearchResultContainer = styled(Box)(({ theme: t }) => (
  `
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${t.breakpoints.down('sm')} {
    height: 100%;
  };
  `
));

export const SearchAllTagsColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  ['& .MuiBox-root:last-child .MuiBox-root .MuiDivider-root']: {
    visibility: 'hidden',
  },
}))

export const SearchByStringSuggest = styled(Box)(({ theme: t }) => (`
  position: sticky;
  z-index: 3;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: ${`1px solid ${t.palette.grey[300]}`};
  background-color: white;

  ${t.breakpoints.down('sm')} {
    max-height: 160px;
    min-height: 160px;
  };
`));

export const AssetsQueryButtonStyled = styled(Button)(({ theme: t }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: '2px',
  paddingRight: '4px',
  height: '24px',
  backgroundColor: t.palette.grey[200],
  borderRadius: t.borderRadius.sm,
  fontSize: t.typography.body2.fontSize,
  color: t.palette.primary.main,
  textTransform: 'none',
  ['&:hover']: {
    backgroundColor: t.palette.grey[300]
  }
}));

export const AssetsQueryButton: FC<{ onClick: () => void, value: string }> = ({ onClick, value }) => (
  <AssetsQueryButtonStyled
    disableElevation
    disableFocusRipple
    variant="contained"
    startIcon={<Search sx={t => ({ width: 18, color: t.palette.grey[600], ml: 1, })} />}
    onClick={onClick}>
    &rdquo;{value}&rdquo;
  </AssetsQueryButtonStyled>
)

export const AssetsSearchAllTagsSkeleton: FC = () => {
  return (
    <Box sx={{
      display: 'grid',
      gridColumnGap: '8px',
      gridRowGap: '8px',
      gridTemplateColumns: 'repeat(3, 1fr)',
      width: '100%',
      pb: 2,
      px: 0,
      maxHeight: 300,
      overflow: 'auto',
    }}>
      {[...Array(15).keys()].map(i => <Skeleton sx={{ transform: 'translateY(0)', borderRadius: '4px' }} width={'100%'} height={'30px'} animation="wave" key={i} />)}
    </Box>
  )
}

export const AssetsSearchResultTagsSkeleton: FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, pb: 2, px: 1, py: 1, }}>
      <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 30, mb: 1, }}>
        <Skeleton width={120} height={26} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
      </Box>
      <Box sx={{
        display: 'grid',
        gridColumnGap: '16px',
        gridRowGap: '0',
        gridTemplateColumns: 'repeat(3, 1fr)',
        width: '100%',
        maxHeight: 300,
        overflow: 'auto',
      }}>
        {[...Array(6).keys()].map((i) => (
          <Box key={`tag_${i}`} sx={{ display: 'flex', flexDirection: 'column', mb: 2, }}>
            <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 40, backgroundColor: 'white', }}>
              <Skeleton width={'60%'} height={26} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', }}>
              {[...Array(4).keys()].map((j) => (
                <Skeleton key={`tag_${i}_${j}`} width={'90%'} height={26} sx={{ transform: 'translateY(0)', borderRadius: '4px', mb: 1, }} />
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
