import { Box } from "@mui/material";
import type { TagGroupEntry } from "features/assets";
import { useTagGroups } from "features/assets";
import { useCallback, type FC, useRef, useEffect } from "react";
import AssetsSearchTagItem from "./AssetsSearchTagItem";
import { SearchAllTagsColumn } from "./AssetsSearchComponents";

const generateColumns = (arr: TagGroupEntry[], columnsCount: number) => {
  const perColumns = Math.ceil(arr.length / columnsCount);
  return Array.from({ length: columnsCount }).reduce<TagGroupEntry[][]>((result: TagGroupEntry[][], _, i: number) => {
    const column = arr.slice((i * perColumns), (i + 1) * perColumns);
    result.push(column);
    return result;
  }, []);
};

const AssetsSearchAllTags: FC<{ onScrollCallback?: (value: number) => void }> = ({ onScrollCallback }) => {
  const allTagsRef = useRef<HTMLElement | null>(null);

  const { data: { data } } = useTagGroups();
  const dataColumns: TagGroupEntry[][] = generateColumns(data, 3);

  const onScroll = useCallback(() => {
    if( typeof onScrollCallback === 'function' && allTagsRef.current ) {
      onScrollCallback(allTagsRef.current.scrollTop)
    }
}, [onScrollCallback, allTagsRef.current]);

  const tagItemsMapper = useCallback((tagGroup: TagGroupEntry) => (
    <AssetsSearchTagItem
      key={`allTagGroups.${tagGroup.group_id}:${tagGroup.group_name}`}
      tagGroup={tagGroup} />
  ), []);

  useEffect(() => {
      if( allTagsRef.current ) {
        allTagsRef.current.addEventListener('scroll', onScroll);
      }
    return () => {
      if( allTagsRef.current ) {
        allTagsRef.current.removeEventListener('scroll', onScroll);
      }
    };
  } , [allTagsRef.current]);

  return (
    <Box
      ref={allTagsRef}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        maxHeight: 280,
        width: '100%',
        p: 0,
        mb: 0,
        overflow: 'auto',
      }}>
      <SearchAllTagsColumn>
        {dataColumns[0].map(tagItemsMapper)}
      </SearchAllTagsColumn>
      <SearchAllTagsColumn>
        {dataColumns[1].map(tagItemsMapper)}
      </SearchAllTagsColumn>
      <SearchAllTagsColumn>
        {dataColumns[2].map(tagItemsMapper)}
      </SearchAllTagsColumn>
    </Box>
  )
}

export default AssetsSearchAllTags;
