import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import type { FC } from 'react';

const UsersTableSkeleton: FC = () => {
  return (
    <>
      <Box sx={{ display: 'flex', flexGrow: 1, width: '100%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: 200, width: 500 }} style={{ paddingLeft: '4px' }}>
                  <Skeleton width={120} height={32} />
                </TableCell>
                <TableCell sx={{ minWidth: 200, width: 300 }}>
                  <Skeleton width={120} height={32} />
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Skeleton width={100} height={32} />
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  <Skeleton width={60} height={32} />
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Skeleton width={60} height={32} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(15).keys()].map((i) => (
                <TableRow key={i}>
                  <TableCell style={{ paddingLeft: '4px' }}>
                    <Skeleton width={140} height={32} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={80} height={32} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={80} height={32} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={80} height={32} />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Skeleton width={86} height={32} sx={{ mr: 2 }} />
                      <Skeleton width={32} height={32} />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default UsersTableSkeleton;
