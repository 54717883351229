import { Grid, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import type { FC } from 'react';
import type { UserAccessLevel } from '../api';
import { useUsersList } from '../api';
import useUsersListParams from '../hooks/useUsersListParams';
import { usePagination } from 'hooks';
import UsersTableHead from './UsersTableHead';
import UsersTableRowItem from './UsersTableRowItem';
import { isEmpty } from 'lodash-es';
import UsersTableEmpty from './UsersTableEmpty';

const UsersTable: FC = () => {
  const { queryString, accessLevel, orderBy, desc } = useUsersListParams();
  const { perPage, page, handlePageChange, handleRowsPerPageChange } = usePagination(
    [queryString, accessLevel, orderBy, desc],
    50
  );
  const {
    data: { data: usersList, count },
  } = useUsersList({
    params: {
      q: queryString,
      order_by: orderBy,
      desc,
      access_level: accessLevel as UserAccessLevel[],
      offset: page,
      limit: perPage,
    },
  });

  return !isEmpty(usersList) ? (
    <Grid container flexDirection={'column'}>
      <TableContainer sx={{ overflow: 'scroll' }}>
        <Table sx={{ width: '100%' }}>
          <UsersTableHead />
          <TableBody>
            {usersList.map((u) => (
              <UsersTableRowItem key={u.id} user={u} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Rows per page:"
        component="div"
        count={count}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={perPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Grid>
  ) : (
    <UsersTableEmpty />
  );
};

export default UsersTable;
